<template>
  <div class="flex flex-1" id="container_batiments">
    <LayoutMapAndTools :initOpenToolBar="false">
      <template #footer-details-infos>
        <DetailsBatiments
          class="w-[45rem] lg:w-full"
          v-if="current_batiments.length"
        />
        <EmptyStateOnMap title="Bâtiment" name_icon="batiments" v-else>
          <template #message-error>
            <span class="text-promy-gray-250"
              >La parcelle ne contient <br />
              aucun bâtiment.</span
            >
          </template>
        </EmptyStateOnMap>
      </template>
      <template #layers>
        <MapType :layers="layers" />
      </template>
      <template #map>
        <Map></Map>
      </template>
    </LayoutMapAndTools>
  </div>
</template>

<script>
import Mapbox from '@/mixins/map/mapbox'
import LayoutMapAndTools from '../LayoutMapAndTools.vue'
import DetailsBatiments from './DetailsBatiments.vue'
import Map from './map.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    LayoutMapAndTools,
    DetailsBatiments,
    Map,
  },
  data() {
    return {
      toggle_actions: true,
      layers: [],
    }
  },
  mixins: [Mapbox],
  computed: {
    ...mapGetters({
      current_batiments: 'parcelle/current_batiments',
      infos_commune: 'address/infos_commune',
    }),
  },
  created() {
    this.layers = [
      {
        label: 'Cadastre',
        image: 'Cadastre.svg',
        is_active: false,
        component: 'CadastreOfficiel',
        data: {
          code_insee: this.infos_commune.code_insee,
        },
      },
      {
        label: '3D',
        image: '3D.svg',
        is_active: true,
        component: 'switchDimension',
      },
    ]
  },
  beforeRouteLeave(to, from, next) {
    this.$destroy()
    next()
  },
}
</script>
