<template>
  <DetailsAccordion
    title="Bâtiments"
    :adresse="address.information.properties.label"
    :active_first_accordion_item="active_first_accordion_item"
    ref="detailsAccordionBatiments"
  >
    <template #right-side>
      <ProSelect
        class="md:hidden"
        :args="['label']"
        :data="list_batis"
        @valueSelect="($event) => (dropdown_batiment_selected = $event)"
        v-model="dropdown_batiment_selected"
        :is_top="true"
      />
    </template>
    <template #body>
      <ProSelect
        class="hidden md:block mt-4"
        :args="['label']"
        :data="list_batis"
        @valueSelect="($event) => (dropdown_batiment_selected = $event)"
        v-model="dropdown_batiment_selected"
      />
      <div class="grid grid-cols-3 gap-4 py-8 text-sm">
        <div class="sm:col-span-3">
          <div class="flex space-x-2">
            <pro-icon name="surface-sol" width="26px" height="18px" />
            <div class="pt-[1px]">
              <p>Surface au sol</p>
              <p class="font-normal">
                {{ totalSurfaceSolBatis(batis_selected) | roundDouble }}
                M²
              </p>
            </div>
          </div>
        </div>

        <div class="sm:col-span-3">
          <div class="flex space-x-2">
            <pro-icon name="perimetre-batiment" width="18px" height="19px" />
            <div class="pt-[1px]">
              <p>Périmètre bâtiment(s)</p>
              <p class="font-normal">
                {{ totalPerimetreBatis(batis_selected) | roundDouble }}
                M
              </p>
            </div>
          </div>
        </div>

        <div class="sm:col-span-3">
          <div class="flex space-x-2">
            <pro-icon name="wall" width="19px" height="17px" />
            <div class="pt-[1px]">
              <p>Nombre de murs</p>
              <p class="font-normal">
                {{ totalNombreMursBatis(batis_selected) }}
              </p>
            </div>
          </div>
        </div>

        <template v-if="batis_selected.length === 1">
          <div
            class="sm:col-span-3"
            v-if="batis_selected[0].properties.hauteur"
          >
            <div class="flex space-x-2">
              <pro-icon name="height" width="21px" height="19px" />
              <div class="pt-[1px]">
                <p>Hauteur</p>
                <p class="font-normal">
                  {{ batis_selected[0].properties.hauteur }} M
                </p>
              </div>
            </div>
          </div>

          <div
            class="sm:col-span-3"
            v-if="batis_selected[0].properties.nombre_d_etages"
          >
            <div class="flex space-x-2">
              <pro-icon name="three-layers" width="22px" height="20px" />
              <div class="pt-[1px]">
                <p>Nombre de niveaux déclaré</p>
                <p class="font-normal">
                  {{ batis_selected[0].properties.nombre_d_etages }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="sm:col-span-3"
            v-if="
              batis_selected[0].properties.hauteur 
            "
          >
            <div class="flex space-x-2">
              <pro-icon name="three-layers" width="22px" height="20px" />
              <div class="pt-[1px]">
                <p>Nombre de niveaux estimé</p>
                <p class="font-normal">
                  {{ (batis_selected[0].properties.hauteur / 3) | roundNumber }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </DetailsAccordion>
</template>
<script>
import { mapGetters } from 'vuex'
import batiment from '@/mixins/batiment'
import Mapbox from '@/mixins/map/mapbox'

export default {
  data() {
    return {
      dropdown_batiment_selected: null,
      list_batis: [],
      batis_selected: [],
      active_first_accordion_item: false,
    }
  },
  mixins: [batiment, Mapbox],
  computed: {
    ...mapGetters({
      address: 'address/address',
      current_batiments: 'parcelle/current_batiments',
      batiment_selected: 'parcelle/batiment_selected',
      map: 'map/map',
    }),
  },
  created() {
    this.init()
    if (window.innerWidth > 768) this.active_first_accordion_item = true
  },
  watch: {
    dropdown_batiment_selected: {
      deep: true,
      handler() {
        this.batis_selected =
          this.dropdown_batiment_selected.index === -1
            ? this.current_batiments
            : [this.current_batiments[this.dropdown_batiment_selected.index]]
        if (this.map.isStyleLoaded()) {
          let isSameBati =
            this.batis_selected.length > 1
              ? true
              : this.isSameBati(this.batiment_selected, this.batis_selected[0])
          this.setLayerSelectedBatiment(this.batis_selected[0], isSameBati)
          this.$store.commit(
            'parcelle/BATIMENT_SELECTED',
            isSameBati ? null : this.batis_selected[0],
          )
          let fc_batiments = this.$turf.featureCollection(this.batis_selected)
          this.fitBoundsGeojsonInMap(fc_batiments)
        }
      },
    },
    batiment_selected(bati) {
      this.$refs.detailsAccordionBatiments.activeAccordion()
      this.dropdown_batiment_selected = bati
        ? this.list_batis[bati.properties.id + 1]
        : this.list_batis[0]
    },
  },
  methods: {
    init() {
      this.list_batis = [
        {
          label: `Tous les bâtiments (${this.current_batiments.length})`,
          index: -1,
        },
      ]
      this.dropdown_batiment_selected = this.list_batis[0]
      if (this.current_batiments.length > 1) {
        this.current_batiments.forEach((bati, index) => {
          this.list_batis.push({
            label: `Bâtiment ${index + 1}`,
            index: index,
          })
        })
      }
    },
  },
}
</script>
